<template>
  <el-card>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="title">
          字典列表
          <el-button size="small"
                     type="primary"
                     icon="el-icon-plus"
                     @click="saveDict(null)">
            新增字典
          </el-button>
        </div>
        <el-table v-loading="loading1"
                  border
                  fit
                  highlight-current-row
                  @row-click="dictClick"
                  :data="dicts"
                  stripe>
          <el-table-column prop="dictName"
                           label="字典名称" />
          <el-table-column prop="dictType"
                           label="字典类型" />
          <el-table-column prop="orderNum"
                           label="排序号" />
          <el-table-column label="操作"
                           width="85px">
            <template slot-scope="{row}">
              <el-link class="mr10"
                       :underline="false"
                       @click.stop="saveDict(row)"
                       type="primary">
                <el-tooltip content="修改"
                            placement="top"
                            effect="light">
                  <i class="el-icon-edit" />
                </el-tooltip>
              </el-link>
              <el-link :underline="false"
                       class="mr10"
                       @click.stop="delDict(row)"
                       type="primary">
                <el-tooltip content="删除"
                            placement="top"
                            effect="light">
                  <i class="el-icon-delete" />
                </el-tooltip>
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <pagination v-show="dictQuery.total>0"
                    :total="dictQuery.total"
                    :page.sync="dictQuery.page"
                    :limit.sync="dictQuery.pageSize"
                    @pagination="getDictList" />
      </el-col>
      <el-col :span="12">
        <div class="title">
          字典数据列表
          <el-button size="small"
                     type="primary"
                     icon="el-icon-plus"
                     @click="saveDictData(null)">
            新增数据
          </el-button>
        </div>
        <el-table v-loading="loading2"
                  border
                  fit
                  highlight-current-row
                  :data="dictDatas"
                  stripe>
          <el-table-column prop="label"
                           label="数据名称" />
          <el-table-column prop="dictType"
                           label="字典类别" />
          <el-table-column prop="parentValue"
                           label="父级值" />
          <el-table-column prop="value"
                           label="子级值" />
          <el-table-column prop="sortNo"
                           label="排序号" />
          <el-table-column label="操作"
                           width="85px">
            <template slot-scope="{row}">
              <el-link class="mr10"
                       :underline="false"
                       @click.stop="saveDictData(row)"
                       type="primary">
                <el-tooltip content="修改"
                            placement="top"
                            effect="light">
                  <i class="el-icon-edit" />
                </el-tooltip>
              </el-link>
              <el-link :underline="false"
                       class="mr10"
                       @click.stop="delDictData(row)"
                       type="primary">
                <el-tooltip content="删除"
                            placement="top"
                            effect="light">
                  <i class="el-icon-delete" />
                </el-tooltip>
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <pagination v-show="dictDataQuery.total>0"
                    :total="dictDataQuery.total"
                    :page.sync="dictDataQuery.page"
                    :limit.sync="dictDataQuery.pageSize"
                    @pagination="getDictDataList" />
      </el-col>
    </el-row>
    <DictionariesDialog :title="title"
                        :type="type"
                        :form="form"
                        ref="dictionariesDialog"
                        @handleFilter="handleFilter" />
  </el-card>
</template>

<script>
import pagination from '@core/components/pagination/Pagination.vue'
import {
  delDict,
  getDict,
  delDictData,
  getDictData,
} from '@/api/system/dictionaries'
import { error, success } from '@core/utils/utils'

import DictionariesDialog from '@/views/settings/dictionaries/DictionariesDialog.vue'

export default {
  components: {
    pagination,
    DictionariesDialog
  },
  data () {
    return {
      loading1: true,
      dicts: [],
      dictQuery: {
        total: 0,
        page: 1,
        pageSize: 10,
      },
      loading2: true,
      dictDatas: [],
      dictDataQuery: {
        dictType: 0,
        total: 0,
        page: 1,
        pageSize: 10,
      },
      type: 1,
      title: '',
      form: {}
    }
  },
  methods: {
    getDictList () {
      this.loading1 = true
      getDict(this.dictQuery).then(res => {
        this.loading1 = false
        this.dicts = res.data.data.list
        this.dictQuery.total = Number(res.data.data.total)
        if (this.dicts.length > 0) {
          this.dictDataQuery.dictType = this.dicts[0].dictType
          this.dictDataQuery.page = 1
          this.getDictDataList()
        }
      }).catch(() => {
        this.loading1 = false
      })
    },
    saveDict (row) {
      this.form = {
        dictName: '',
        dictType: '',
        orderNum: '',
        remark: ''
      }
      if (row) {
        this.form.id = row.id
        this.form.dictName = row.dictName
        this.form.dictType = row.dictType
        this.form.orderNum = row.orderNum
        this.form.remark = row.remark
        this.title = '修改字典'
      } else {
        this.title = '新增字典'
      }
      this.type = 1
      this.$refs.dictionariesDialog.dialogVisible = true
    },
    handleFilter (type) {
      if (type) {
        this.dictDataQuery.page = 1
        this.getDictDataList()
      } else {
        this.dictQuery.page = 1
        this.getDictList()
      }
    },
    dictClick (row) {
      this.dictDataQuery.dictType = row.dictType
      this.dictDataQuery.page = 1
      this.getDictDataList()
    },
    delDict (row) {
      this.$confirm(`是否确认删除"${row.dictName}"吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        delDict({ ids: [Number(row.id)] }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.handleFilter()
          } else {
            error(resData.msg)
          }
        })
      }).catch(() => { })
    },
    getDictDataList () {
      this.loading2 = true
      getDictData(this.dictDataQuery).then(res => {
        this.loading2 = false
        this.dictDatas = res.data.data.list
        this.dictDataQuery.total = Number(res.data.data.total)
      }).catch(() => {
        this.loading2 = false
      })
    },
    saveDictData (row) {
      this.form = {
        label: '',
        dictType: this.dictDataQuery.dictType,
        parentValue: '',
        value: '',
        sortNo: '',
        remark: ''
      }
      if (row) {
        this.form.id = row.id
        this.form.label = row.label
        this.form.parentValue = row.parentValue
        this.form.value = row.value
        this.form.sortNo = row.sortNo
        this.form.remark = row.remark
        this.title = '修改字典数据'
      } else {
        this.title = '新增字典数据'
      }
      this.type = 2
      this.$refs.dictionariesDialog.dialogVisible = true
    },
    delDictData (row) {
      this.$confirm(`是否确认删除"${row.label}"吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        delDictData({ ids: [Number(row.id)] }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.handleFilter(1)
          } else {
            error(resData.msg)
          }
        })
      }).catch(() => { })
    },
  },
  created () {
    this.getDictList()
  },
}
</script>

<style scoped>
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  font-size: 16px;
}
</style>