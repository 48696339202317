<template>
  <el-dialog :title="title"
             :visible.sync="dialogVisible"
             width="500px"
             @open="dialogOpen"
             :append-to-body="true"
             :modal-append-to-body="false">
    <el-form ref="form1"
             v-if="type===1"
             :model="form1"
             label-width="100px">
      <el-form-item label="字典名称"
                    prop="dictName"
                    :rules="[ { required: true, message: '字典名称不能为空', trigger: 'change' }]">
        <el-input v-model="form1.dictName"
                  placeholder="请输入字典名称" />
      </el-form-item>
      <el-form-item label="字典类别"
                    prop="dictType"
                    :rules="[ { required: true, message: '字典类别不能为空', trigger: 'change' }]">
        <el-input v-model="form1.dictType"
                  :disabled="!!form1.id"
                  placeholder="请输入字典类别" />
      </el-form-item>
      <el-form-item label="排序号">
        <el-input v-model="form1.orderNum"
                  placeholder="请输入排序号"
                  type="number" />
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form1.remark"
                  placeholder="请输入备注"
                  type="textarea" />
      </el-form-item>
    </el-form>
    <el-form ref="form2"
             v-else
             :model="form2"
             label-width="100px">
      <el-form-item label="字典数据名称"
                    prop="label"
                    :rules="[ { required: true, message: '字典数据名称不能为空', trigger: 'change' }]">
        <el-input v-model="form2.label"
                  type="textarea"
                  placeholder="请输入字典数据名称" />
      </el-form-item>
      <el-form-item label="字典类别"
                    prop="dictType"
                    :rules="[ { required: true, message: '字典类别不能为空', trigger: 'change' }]">
        <el-select v-model="form2.dictType"
                   filterable
                   :disabled="!!form2.id">
          <el-option v-for="item in dicts"
                     :key="item.id"
                     :label="item.dictType"
                     :value="item.dictType" />
        </el-select>
      </el-form-item>
      <el-form-item label="字典值"
                    prop="value"
                    :rules="[ { required: true, message: '字典值不能为空', trigger: 'change' }]">
        <el-input v-model="form2.value"
                  type="textarea"
                  placeholder="请输入字典值" />
      </el-form-item>
      <el-form-item label="父级值"
                    prop="parentValue">
        <el-input v-model="form2.parentValue"
                  type="textarea"
                  placeholder="请输入父级值" />
      </el-form-item>
      <el-form-item label="排序号"
                    prop="sortNo"
                    :rules="[ { required: true, message: '排序号不能为空', trigger: 'change' }]">
        <el-input v-model="form2.sortNo"
                  placeholder="请输入排序号"
                  type="number" />
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form2.remark"
                  placeholder="请输入备注"
                  type="textarea" />
      </el-form-item>
    </el-form>
    <div slot="footer"
         class="dialog-footer">
      <el-button size="small"
                 @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button type="primary"
                 size="small"
                 :disabled="loading"
                 @click="submitForm">
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  saveDict,
  getAllDict,
  saveDictData,
} from '@/api/system/dictionaries'
import { error, success } from '@core/utils/utils'
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: Number,
      default: 1,
    },
    form: {
      type: Object,
      default: {},
    },
  },
  data () {
    return {
      dialogVisible: false,
      loading: false,
      form1: {
        dictName: '',
        dictType: '',
        orderNum: '',
        remark: ''
      },
      form2: {
        label: '',
        dictType: '',
        parentValue: '',
        value: '',
        sortNo: '',
        remark: ''
      },
      dicts: []
    }
  },
  methods: {
    submitForm () {
      if (this.type === 1) {
        this.saveDict()
      } else {
        this.saveDictData()
      }
    },
    saveDict () {
      this.$refs.form1.validate(valid => {
        if (valid) {
          this.loading = true
          this.form1.orderNum = Number(this.form1.orderNum)
          saveDict(this.form1).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          })
        } else {
          return false
        }
      })
    },
    saveDictData () {
      this.$refs.form2.validate(valid => {
        if (valid) {
          this.loading = true
          this.form2.sortNo = Number(this.form2.sortNo)
          saveDictData(this.form2).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter', 1)
            } else {
              error(resData.msg)
            }
          })
        } else {
          return false
        }
      })
    },
    dialogOpen () {
      if (this.type === 1) {
        this.form1 = this.form
      } else {
        this.form2 = this.form
        getAllDict().then(res => {
          this.dicts = res.data.data
        })
      }
    }
  },
}
</script>

<style scoped>
</style>
