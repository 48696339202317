import { get, post } from '@/libs/axios'

// 创建或更新Dict
export const saveDict = params => {
  const submitUrl = params.id ? '/sys/dict/Update': '/sys/dict/Create'
  return post(submitUrl, params)
}
export const getAllDict = () => get(`/sys/dict/GetAllDictList`)
export const delDict = params => post('/sys/dict/DeleteByIds', params)
export const getDict = params => post('/sys/dict/page', params)

// 创建或更新DictData
export const saveDictData = params => {
  const submitUrl = params.id ? '/sys/dictData/Update': '/sys/dictData/Create'
  return post(submitUrl, params)
}
export const delDictData = params => post('/sys/dictData/DeleteByIds', params)
export const getDictData = params => post('/sys/dictData/page', params)
